<template>
  <div class="wrapper">
    <div class="sign-in-up forgot-password" id="log-out">
      <div class="overlay"></div>
      <div class="form">
        <div class="form__content">
          <div class="lock-icon">
            <span>
              <logout-icon />
            </span>
          </div>
          <h2>You are logged out</h2>

          <p class="mt-3 text-secondary">Need to log in to continue</p>

          <div class="button mb-4">
            <router-link :to="logoutUrl" class="btn w-100 btn-red"
              ><span><strong>Log In</strong></span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LogoutIcon from "./LogoutIcon.vue";

export default {
  name: "LogOut",
  components: { LogoutIcon },
  data() {
    return {
      signInBg: "",
    };
  },

  watch: {
    signInBg(newVal) {
      if (newVal) {
        let theBox = document.getElementById("log-out");
        theBox.style.backgroundImage = `url(${newVal})`;
        theBox.style.backgroundSize = "cover";
      }
    },
  },
  mounted() {
    this.signInBg = this.getAssets.agentPortalBackground;
    this.fetchLoaderStatus(false);
  },
  computed: {
    ...mapGetters([
      "getAssets",
      "getCompanyName",
      "getProspectId",
      "getOrgId",
      "getAppFlow",
      "getIsCoPilot",
      "getCoPilotEmail",
    ]),

    logoutUrl() {
      return this.getIsCoPilot
        ? "/" +
            this.getCompanyName +
            "/" +
            this.getProspectId +
            "&" +
            this.getOrgId +
            "?email=" +
            this.getCoPilotEmail +
            "&password=&type=" +
            this.getAppFlow
        : "/sign-in";
    },
  },
  methods: {
    ...mapActions(["fetchLoaderStatus"]),
  },
};
</script>